import React, { useState } from 'react';

const LegalPages = ({ topRef }) => {
  const [currentPage, setCurrentPage] = useState('mentions');

  // Contenu des pages
  const pages = {
    mentions: (
      <div>
        <h2 className="text-2xl font-bold mb-4">Mentions Légales</h2>
        <h3 className="text-xl font-semibold mt-6 mb-2">1. Identification de l'éditeur</h3>
        <p>
          Le présent site web est édité par K-Software.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">2. Propriété intellectuelle</h3>
        <p>
          Tous les éléments présents sur ce site (textes, images, logos, graphiques, etc.) sont protégés par le droit d'auteur et restent la propriété exclusive de <strong>Platinium</strong> ou de ses partenaires. Toute reproduction, distribution ou utilisation sans autorisation est interdite.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">3. Responsabilité</h3>
        <p>
          <strong>Platinium Music</strong> ne saurait être tenu responsable des erreurs, des omissions, ou des conséquences de l'utilisation des informations présentes sur ce site. L'accès à certaines sections du site peut être interrompu temporairement ou définitivement, sans préavis.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">4. Données personnelles</h3>
        <p>
          Conformément à la réglementation en vigueur, <strong>K-Software</strong> s'engage à protéger vos données personnelles. Pour plus d'informations sur la manière dont nous collectons, traitons et protégeons vos données, veuillez consulter notre Politique de Confidentialité.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">5. Cookies</h3>
        <p>
          Ce site n'utilise pas de cookies.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">6. Contact</h3>
        <p>
          Pour toute question concernant les mentions légales ou le fonctionnement du site, veuillez nous contacter à l'adresse suivante :  
          <strong> contact@platiniummusic.fr</strong>
        </p>
      </div>
      
    ),
    cgv: (
        <div>
        <h2 className="text-2xl font-bold mb-4">Conditions Générales de Vente</h2>
        <p>Détails des conditions de prestation, modalités d'annulation, et autres informations liées aux ventes.</p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">1. Objet</h3>
        <p>
          Les présentes Conditions Générales de Vente (CGV) régissent les relations commerciales entre Platinium Music et ses clients pour toute prestation de services et/ou vente de produits. En passant commande, le client accepte sans réserve ces conditions.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">2. Commande</h3>
        <p>
          Toute commande passée par le client est considérée comme une offre d'achat, et nous nous réservons le droit d’accepter ou de refuser cette commande. L’acceptation de la commande est confirmée par l’envoi d’un e-mail de confirmation ou la fourniture du produit ou service demandé.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">3. Prix</h3>
        <p>
          Les prix des produits ou services sont indiqués en euros et incluent toutes les taxes applicables (TVA ou autres taxes). Les frais de livraison peuvent être ajoutés au montant total et sont précisés au moment de la commande.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">4. Modalités de paiement</h3>
        <p>
          Le paiement des produits ou services doit être effectué au moment de la commande, via les moyens de paiement disponibles sur le site. En cas de paiement par carte bancaire, le client garantit qu’il est autorisé à utiliser la carte et que le paiement pourra être effectué.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">5. Livraison</h3>
        <p>
          Les produits seront livrés à l'adresse indiquée lors de la commande. Les délais de livraison sont fournis à titre indicatif et ne sont pas garantis. Le vendeur ne pourra être tenu responsable en cas de retard de livraison.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">6. Droit de rétractation</h3>
        <p>
          Conformément à la loi, le client dispose d'un délai de 14 jours à compter de la réception de la commande pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités. Les produits doivent être retournés dans leur état d'origine et complets, à l'adresse indiquée par le vendeur.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">7. Garanties</h3>
        <p>
          Les produits bénéficient de la garantie légale contre les vices cachés et la conformité. En cas de produit défectueux, le client doit informer le vendeur dans un délai raisonnable après la découverte du défaut. Le vendeur procédera alors à un échange ou un remboursement.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">8. Responsabilité</h3>
        <p>
          Le vendeur ne pourra être tenu responsable des dommages directs ou indirects causés par l’utilisation des produits ou services, sauf en cas de faute lourde ou de négligence grave de sa part.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">9. Force majeure</h3>
        <p>
          Le vendeur ne pourra être tenu responsable de l’inexécution de ses obligations en cas de force majeure, telle que définie par la loi.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">10. Modification des CGV</h3>
        <p>
          Le vendeur se réserve le droit de modifier les présentes Conditions Générales de Vente à tout moment. Les nouvelles conditions seront applicables dès leur publication sur le site.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">11. Droit applicable et litiges</h3>
        <p>
          Les présentes CGV sont soumises au droit francais, sans égard aux principes de conflits de lois.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">12. Contact</h3>
        <p>
          Pour toute question relative à ces Conditions Générales de Vente, le client peut contacter le vendeur à l'adresse suivante :  
          <strong>contact@platiniummusic.fr</strong>
        </p>
      </div>
      
    ),
    politique: (
        <div>
        <h2 className="text-2xl font-bold mb-4">Politique de Confidentialité</h2>
        <p>Comment nous utilisons et protégeons vos données personnelles conformément aux réglementations en vigueur.</p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">1. Collecte de données personnelles</h3>
        <p>
          Nous collectons des informations sur les utilisateurs de notre site à travers l'utilisation d'outils d'analyse tels que 
          <strong> Google Analytics</strong> et <strong>Google Search Console</strong>. Ces outils nous permettent de recueillir des informations sur la manière dont les utilisateurs naviguent sur notre site, comme les pages visitées, le temps passé sur celles-ci, et les interactions avec le contenu. Ces données sont collectées de manière anonyme, sans identification personnelle.
        </p>
        <p>
          Nous n’utilisons pas de cookies pour le suivi des utilisateurs, à l'exception de ceux nécessaires au bon fonctionnement du site.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">2. Utilisation des données collectées</h3>
        <p>
          Les données collectées par Google Analytics et Google Search Console sont utilisées exclusivement à des fins d'analyse pour améliorer l'expérience utilisateur sur notre site. Nous utilisons ces informations pour :
        </p>
        <ul className="list-disc ml-5">
          <li>Analyser les tendances de navigation et la performance du site</li>
          <li>Optimiser le contenu et les fonctionnalités du site</li>
          <li>Améliorer notre stratégie de référencement</li>
        </ul>
        <p>
          Aucune information personnelle identifiable (comme le nom ou l’adresse e-mail) n'est collectée à travers ces outils.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">3. Protection des données</h3>
        <p>
          Nous mettons en place des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre tout accès, utilisation ou divulgation non autorisé. Les données collectées par les outils d'analyse sont sécurisées par des systèmes conformes aux normes de sécurité de Google.
        </p>
        <p>
          Nous ne partageons pas vos données personnelles avec des tiers, sauf si cela est exigé par la loi ou nécessaire pour assurer le bon fonctionnement des services que nous utilisons, comme Google Analytics et Google Search Console.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">4. Durée de conservation des données</h3>
        <p>
          Les données collectées par Google Analytics et Google Search Console sont conservées pendant la durée nécessaire à l'analyse et à l'optimisation du site. Conformément aux pratiques de Google, ces données peuvent être conservées pendant 26 mois, mais vous pouvez également choisir de ne pas être suivi par ces outils en ajustant les paramètres de votre navigateur ou en utilisant des outils spécifiques pour désactiver les cookies.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">5. Vos droits en matière de confidentialité</h3>
        <p>
          Conformément aux réglementations en vigueur, vous disposez de plusieurs droits concernant vos données personnelles, y compris :
        </p>
        <ul className="list-disc ml-5">
          <li>Le droit d'accéder à vos données</li>
          <li>Le droit de rectification de vos données</li>
          <li>Le droit de suppression de vos données (droit à l’oubli)</li>
          <li>Le droit de limiter ou de vous opposer à leur traitement</li>
        </ul>
        <p>
          Si vous avez des questions ou souhaitez exercer vos droits, vous pouvez nous contacter à l'adresse suivante : contact@platiniummusic.fr.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">6. Modifications de cette politique</h3>
        <p>
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec la date de la mise à jour. Nous vous encourageons à consulter régulièrement cette page pour être informé des changements.
        </p>
      
        <h3 className="text-xl font-semibold mt-6 mb-2">7. Contact</h3>
        <p>
          Si vous avez des questions concernant cette politique de confidentialité, ou si vous souhaitez obtenir davantage d'informations sur la manière dont vos données sont traitées, n'hésitez pas à nous contacter à l'adresse suivante :  
          <strong> contact@platiniummusic.fr</strong>
        </p>
      </div>
      
    )
  };

  return (
    <div ref={topRef} className="container mx-auto px-4 py-8">
    <div className="flex flex-wrap items-center space-y-2 space-x-2 sm:space-y-0 sm:space-x-2">
        {Object.keys(pages).map((page) => (
            <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`px-4 py-2 rounded transition-colors ${
                    currentPage === page
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                }`}
            >
                {page === 'mentions' ? 'Mentions Légales' : 
                 page === 'cgv' ? 'CGV' : 
                 'Politique de Confidentialité'}
            </button>
        ))}
    </div>

    {/* Contenu de la page sélectionnée */}
      <div className="bg-white shadow-md p-6 rounded">{pages[currentPage]}</div>
    </div>
  );
};

export default LegalPages;
