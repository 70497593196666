export const performances = [
    {
      id: 1,
      title: 'Cérémonies',
      description: 'Prestation live musicale complète pour votre jour J : mariage, anniversaire, communion, et plus.',
      musiciens: 2,
      inclus: [
        'Répertoire personnalisé',
        'Cérémonie laïque',
        'Cocktail',
        'Première danse'
      ],
      prix: 'Après devis',
      imageUrl:"./images/illus1.webp"
    },
    {
      id: 2,
      title: 'Live show',
      description: 'Pour une ambiance chaleureuse nous vous proposons un live show sur mesure.',
      musiciens: 2,
      inclus: [
        'Choix des chansons',
        'Ambiance adaptée',
        '2h de prestation',
        'Matériel sonore inclus'
      ],
      prix: 'Après devis',
      imageUrl:"./images/illus2.webp"
    },
    {
      id: 3,
      title: "Musique d'ambiance",
      description: 'Ambiance musicale jazz et acoustique pour une amboiance chaleureuse et sophistiqué.',
      musiciens: 1,
      inclus: [
        'Style jazz et acoustique',
        'Adaptabilité à l\'événement',
        'Répertoire varié',
        'Installation rapide'
      ],
      prix: 'Après devis',
      imageUrl:"./images/illus3.webp"
    }
  ];