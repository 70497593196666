import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Navigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              Platinium Music
            </Link>
          </div>
          
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <Link to="/" className="text-gray-700 hover:bg-blue-100 px-3 py-2 rounded-md">Accueil</Link>
              <Link to="/prestations" className="text-gray-700 hover:bg-blue-100 px-3 py-2 rounded-md">Prestations</Link>
              <Link to="/a-propos" className="text-gray-700 hover:bg-blue-100 px-3 py-2 rounded-md">À Propos</Link>
              <Link to="/contact" className="text-gray-700 hover:bg-blue-100 px-3 py-2 rounded-md">Contact</Link>
            </div>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button 
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              type="button" 
              className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500"
            >
              {mobileMenuOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {mobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link 
                to="/" 
                onClick={() => setMobileMenuOpen(false)}
                className="text-gray-700 hover:bg-blue-100 block px-3 py-2 rounded-md"
              >
                Accueil
              </Link>
              <Link 
                to="/prestations" 
                onClick={() => setMobileMenuOpen(false)}
                className="text-gray-700 hover:bg-blue-100 block px-3 py-2 rounded-md"
              >
                Prestations
              </Link>
              <Link 
                to="/a-propos" 
                onClick={() => setMobileMenuOpen(false)}
                className="text-gray-700 hover:bg-blue-100 block px-3 py-2 rounded-md"
              >
                À Propos
              </Link>
              <Link 
                to="/contact" 
                onClick={() => setMobileMenuOpen(false)}
                className="text-gray-700 hover:bg-blue-100 block px-3 py-2 rounded-md"
              >
                Contact
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;