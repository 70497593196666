import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 

const Contact = () => {
  const [formData, setFormData] = useState({
    nom: '',
    email: '',
    telephone: '',
    message: '',
    categorie: '',
    dateEvenement: '',
    lieuEvenement: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Récupérer le titre de la prestation à partir des paramètres de l'URL
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const prestationTitle = params.get('prestation');

  // Effectuer le pré-remplissage du champ "Catégorie"
  useEffect(() => {
    if (prestationTitle) {
      setFormData((prevData) => ({
        ...prevData,
        categorie: prestationTitle,
      }));
    }
  }, [prestationTitle]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Envoyer les données au backend
    const response = await fetch('https://platiniummusic-97624f61db3f.herokuapp.com/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setIsSubmitted(true);
      setFormData({
        nom: '',
        email: '',
        telephone: '',
        message: '',
        categorie: '',
        dateEvenement: '',
        lieuEvenement: '',
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center">Contactez-nous</h2>
      {isSubmitted && (
        <div className="mb-4 p-4 bg-green-100 border border-green-300 text-green-700 rounded">
          Votre demande a bien été envoyée. Vous recevrez une réponse sous 10 jours maximum.
        </div>
      )}
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
        <div className="mb-4">
          <label className="block mb-2 font-medium" htmlFor="nom">Nom</label>
          <input 
            id="nom"
            type="text" 
            required 
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.nom}
            onChange={(e) => setFormData({ ...formData, nom: e.target.value })}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium" htmlFor="email">Email</label>
          <input 
            id="email"
            type="email" 
            required 
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium" htmlFor="telephone">Téléphone (facultatif)</label>
          <input 
            id="telephone"
            type="tel" 
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.telephone}
            onChange={(e) => setFormData({ ...formData, telephone: e.target.value })}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium" htmlFor="categorie">Catégorie</label>
          <select
            id="categorie"
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.categorie}
            onChange={(e) => setFormData({ ...formData, categorie: e.target.value })}
            required
          >
            <option value="">Sélectionner une catégorie</option>
            <option value="Fond de Jazz Guitar">Fond de Jazz Guitar</option>
            <option value="Duo de Jazz">Duo de Jazz</option>
            <option value="Musique Pop/Folk">Musique Pop/Folk</option>
            <option value="Duo de country">Duo de country</option>
            <option value="Autre">Autre (préciser ci-dessous)</option>
          </select>
        </div>
        
          <div className="mb-4">
            <label className="block mb-2 font-medium" htmlFor="messageSpecifique">Description de votre demande spécifique</label>
            <textarea 
              id="messageSpecifique"
              required 
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={4}
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            />
          </div>
        
        <div className="mb-4">
          <label className="block mb-2 font-medium" htmlFor="dateEvenement">Date approximative de l'événement</label>
          <select
            id="dateEvenement"
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.dateEvenement}
            onChange={(e) => setFormData({ ...formData, dateEvenement: e.target.value })}
            required
          >
            <option value="">Sélectionner une date</option>
            <option value="1 semaine">1 semaine</option>
            <option value="1 mois">1 mois</option>
            <option value="supérieur à 1 mois">Supérieur à 1 mois</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium" htmlFor="lieuEvenement">Lieu approximatif de l'événement</label>
          <input 
            id="lieuEvenement"
            type="text"
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.lieuEvenement}
            onChange={(e) => setFormData({ ...formData, lieuEvenement: e.target.value })}
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors"
        >
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default Contact;
