import React from 'react';
import { Music, Guitar, Mic, Star } from 'lucide-react';

const APropos = () => {
  return (
    <div className="container mx-auto px-4 py-12 bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="p-8">
          <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-6 border-b-4 border-blue-500 pb-3 flex items-center">
            <Music className="mr-4 text-blue-500" size={30} />
            À Propos de Platinium Music
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <p className="text-gray-600 leading-relaxed">
                <span className="font-bold text-blue-600">Passionné de musique,</span> je propose des prestations 
                musicales sur mesure à Paris et dans toute la France. Mon style acoustique, 
                country et jazz s'adapte à tous vos événements.
              </p>
              <p className="text-gray-600 leading-relaxed">
                Que ce soit pour un mariage, un anniversaire ou un événement 
                professionnel, je m'engage à créer une ambiance musicale 
                <span className="italic text-blue-500"> unique et mémorable</span>.
              </p>
              <p className="text-gray-600 leading-relaxed">
                Ayant d'abord appris le piano dès le plus jeune âge, puis la guitare, j'ai joué dans 2 groupes de pop et rock.
                Ingénieur de formation, je décide aujourd'hui de me consacrer à ma passion : la musique.
                Me voilà alors auteur, compositeur et arrangeur et mon instrument principal est la guitare.
                Je maîtrise aussi l'harmonica, le ukulele et la batterie par ailleurs.
                </p>
                <p className="text-gray-600 leading-relaxed">
                Entouré par de très bons amis musiciens professionels je propose des prestations seul ou ensemble, selon vos envies. 
                N'hésitez pas à demander un devis (ou une démonstration) si vous aussi vous voulez une <strong>prestation personalisée et abordable</strong> pour votre cérémonie.
              </p>

            </div>
            
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-xl md:text-2xl font-bold mb-5 text-gray-800 flex items-center">
                <Guitar className="mr-3 text-blue-500" size={24} />
                Mes Styles Musicaux
              </h3>
              <ul className="space-y-3">
                {['Acoustique', 'Country', 'Jazz', 'Folk','Pop'].map((style, index) => (
                  <li 
                    key={style} 
                    className="flex items-center text-gray-700 text-base md:text-lg hover:text-blue-600 transition-colors"
                  >
                    <Star className="mr-3 text-blue-400" size={18} />
                    {style}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APropos;
