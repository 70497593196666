import React, {useEffect} from 'react';
import { Instagram, Music } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const Footer = ({ topRef }) => {


  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/legal' && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname, topRef]);

  const scrollToTop = () => {
    // Utilisation de scrollIntoView pour faire défiler jusqu'à l'élément référencé
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-white py-12 shadow-lg">
      <div className="container mx-auto px-4 grid md:grid-cols-3 gap-8">
        {/* Section "À propos" */}
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <Music className="text-blue-400 w-8 h-8" />
            <h3 className="text-2xl font-bold text-blue-300">Platinium Music</h3>
          </div>
          <p className="text-gray-300 leading-relaxed">
            Musique live de haute qualité pour illuminer vos événements à Paris et dans toute la France.
          </p>
        </div>
        
        {/* Section "Contacts" */}
        <div className="space-y-4">
          <h4 className="text-xl font-semibold text-blue-300 mb-4">Contactez-nous</h4>
          <div className="space-y-2">
            <p className="flex items-center space-x-2">
              <span className="text-blue-400">✉</span>
              <a 
                href="mailto:contact@platiniummusic.fr" 
                className="text-gray-200 hover:text-blue-300 transition-colors"
              >
                contact@platiniummusic.fr
              </a>
            </p>
          </div>
        </div>
        
        {/* Section "Réseaux sociaux" */}
        <div className="space-y-4">
          <h4 className="text-xl font-semibold text-blue-300 mb-4">Suivez-nous</h4>
          <div className="flex space-x-6">
            <a 
              href="https://instagram.com/platinium.music" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-pink-400 transition-colors"
              aria-label="Compte Instagram de Platinium Music"
            >
              <Instagram className="w-6 h-6" />
            </a>
          </div>
        </div>
 {/* Section "Légal" */}
 <div className="space-y-4">
      <Link to="/legal" onClick={scrollToTop} className="text-gray-400 leading-relaxed hover:text-blue-500">
        Mentions Légales
      </Link>
    </div>
      </div>
      
   
       
      
      {/* Section des droits d'auteur */}
      <div className="text-center mt-8 border-t border-gray-700 pt-6 text-gray-400">
        <p>© {new Date().getFullYear()} Platinium Music. Tous droits réservés.</p>
        <p className="text-sm mt-2">Une passion pour la musique pour illuminer vos événements.</p>
      </div>
    </footer>
  );
};

export default Footer;