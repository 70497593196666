import React, { useState } from 'react';
import { Music, Mic, Clock, DollarSign, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const performances = [
  {
    id: 1,
    title: 'Fond de Jazz Guitar',
    description: "Une musique d'ambiance chaleureuse et sophistiquée aux sonoritées jazzie pour animer votre événement.",
    musiciens: 1,
    prix: 'Après devis',
    inclus: [
      'Sonorisation complète',
      'Installation et démontage',
      'Répertoire varié',
      'Prestation personalisée',
      "Prestation entre 30 minutes et 1h30"
    ],
    icon: Music
  },
  {
    id: 2,
    title: 'Duo de Jazz',
    description: 'Un duo de jazz pour animer votre événement avec une ambiance chaleureuse et sophistiquée.',
    musiciens: 2,
    prix: 'Après devis',
    inclus: [
      'Sonorisation complète',
      'Installation et démontage',
      'Répertoire varié',
      'Prestation personalisée'
    ],
    icon: Music
  },
  {
    id: 3,
    title: 'Musique Pop/Folk',
    description: 'Une prestation acoustique de tous vos morceaux preférés pour faire danser vos invités (ou pas) avec une sélection musicale adaptée à vos goûts.',
    musiciens: 1,
    prix: 'Après devis',
    inclus: [
      'Sonorisation complète',
      'Playlist personnalisée possible',
      'Animation interactive',
      "Prestation entre 45 minutes et 1h30"
    ],
    icon: Mic
  },
  {
    id: 4,
    title: 'Duo de country',
    description: 'Une prestation acoustique de tous vos morceaux preférés pour faire danser vos invités (ou pas) avec une sélection musicale adaptée à vos goûts.',
    musiciens: 2,
    prix: 'Après devis',
    inclus: [
      'Sonorisation complète',
      'Playlist personnalisée possible',
      'Animation interactive',
      "Prestation entre 45 minutes et 1h30"
    ],
    icon: Mic
  },
];

const PrestationCard = ({ perf, onContactClick }) => {
  const IconComponent = perf.icon;

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-2xl">
      <div className="p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-100 rounded-full p-3 mr-4">
            <IconComponent className="w-8 h-8 text-blue-600" />
          </div>
          <h3 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800">{perf.title}</h3>
        </div>

        <p className="text-gray-600 mb-4 text-sm sm:text-base">{perf.description}</p>

        <div className="grid grid-cols-2 gap-4 mb-4 text-xs sm:text-sm">
          <div className="flex items-center">
            <Mic className="w-5 h-5 mr-2 text-blue-500" />
            <span className="font-medium">Musiciens : {perf.musiciens}</span>
          </div>
          <div className="flex items-center">
            <DollarSign className="w-5 h-5 mr-2 text-green-500" />
            <span className="font-medium">Prix : {perf.prix} €</span>
          </div>
        </div>

        <div className="mb-4">
          <h4 className="font-semibold mb-2 text-gray-700">Ce qui est inclus :</h4>
          <ul className="space-y-2">
            {perf.inclus.map((item, idx) => (
              <li key={idx} className="flex items-center text-gray-600 text-sm">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-6">
          <button
            onClick={() => onContactClick(perf.title)}  // Cette fonction est modifiée pour rediriger
            className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center space-x-2"
          >
            <span>Demander cette prestation</span>
            <Clock className="w-5 h-5 hidden sm:block" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Prestations = () => {
  const navigate = useNavigate();  // Hook pour la navigation
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPrestation, setSelectedPrestation] = useState(null);

  const handleContactClick = (prestationTitle) => {
    setSelectedPrestation(prestationTitle);
    // Rediriger vers le formulaire de contact avec le paramètre de la prestation
    navigate(`/contact?prestation=${prestationTitle}`);
  };

  return (
    <div className="bg-gray-50 min-h-screen py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">Nos Prestations Musicales</h2>
          <p className="text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto">
            Découvrez nos prestations uniques pour donner une dimension musicale exceptionnelle à votre événement.
          </p>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {performances.map((perf) => (
            <PrestationCard key={perf.id} perf={perf} onContactClick={handleContactClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prestations;
