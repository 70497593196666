import React from 'react';
import { Calendar, Clock, MapPin, Music } from 'lucide-react';

const PerformanceCard = ({ performance }) => {
  // Destructure performance details with default values
  const {
    title = 'Performance sans titre',
    description = 'Aucune description disponible',
    date = null,
    time = null,
    location = null,
    genre = 'Musique',
    price = null,
    imageUrl = '/api/placeholder/400/250'
  } = performance || {};

return (
    <div className="bg-white shadow-lg rounded-xl overflow-hidden transition-all duration-300 hover:shadow-xl max-w-sm w-full">
        {/* Performance Image */}
        <div className="relative">
            <img 
                src={imageUrl} 
                alt={title} 
                className="w-full h-48 object-cover"
            />
            <div className="absolute top-0 right-0 bg-black/60 text-white px-3 py-1 m-2 rounded">
                <span className="flex items-center">
                    <Music className="w-4 h-4 mr-2" />
                    {genre}
                </span>
            </div>
        </div>

        {/* Performance Details */}
        <div className="p-5 space-y-3">
            <h2 className="text-xl font-bold text-gray-800 mb-2">{title}</h2>
            <p className="text-gray-600 line-clamp-3">{description}</p>

            {/* Performance Metadata */}
            <div className="space-y-2">
                {date && (
                    <div className="flex items-center text-gray-700">
                        <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                        <span>{new Date(date).toLocaleDateString('fr-FR', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}</span>
                    </div>
                )}

                {time && (
                    <div className="flex items-center text-gray-700">
                        <Clock className="w-5 h-5 mr-2 text-green-500" />
                        <span>{time}</span>
                    </div>
                )}

                {location && (
                    <div className="flex items-center text-gray-700">
                        <MapPin className="w-5 h-5 mr-2 text-red-500" />
                        <span>{location}</span>
                    </div>
                )}
            </div>

            {/* Pricing and Action */}
            <div className="flex justify-between items-center mt-4">
                {price ? (
                    <span className="text-lg font-semibold text-blue-600">
                        {typeof price === 'number' 
                            ? `${price.toFixed(2)} €` 
                            : price}
                    </span>
                ) : (
                    <span className="text-gray-500 italic">Prix sur réservation</span>
                )}

                <button 
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                    onClick={() => { window.location.href = '/prestations'; }}
                >
                    Accéder
                </button>
            </div>
        </div>
    </div>
);
};

export default PerformanceCard;